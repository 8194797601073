import React, { SetStateAction, useEffect, useRef, useState } from "react";
import { IDataBaseConnection } from "../../Services/TemplateService/ITemplateService";
import { toastError, toastSuccess } from "../Toast/Toast";
import AgentService from "../../Services/AgentServices/AgentService";
import { confirmAlert } from "react-confirm-alert";
import DataTable, { TableColumn } from "react-data-table-component";
import { FaTimes } from "react-icons/fa";
import { LuPen, LuPlus, LuTrash } from "react-icons/lu";
import IAgentService from "../../Services/AgentServices/IAgentService";
import { DATABASE_CONNECTION_INITIAL_OBJ } from "../../Constants/constants";

interface DataBaseProps {
    SetUpdateAssistant: React.Dispatch<SetStateAction<IAgentService>>
    UpdateAssistant: IAgentService
    DisableSubmitButton:any
}

const Database: React.FC<DataBaseProps> = ({ SetUpdateAssistant, UpdateAssistant, DisableSubmitButton }) => {

    const [showKeysFormPopup, setShowKeysFormPopup] = useState(false);
    const clickedPartIndexRef = useRef(0);
    const [showLoader, setShowLoader] = useState(false);
    const [buttonDisable, setButtonDisable] = useState(false);
    const [tableList, setTableList] = useState<{ [key: string]: boolean }[]>([]);
    const [showViewBtn, setShowViewBtn] = useState(false);
    const isDatabaseEdit = useRef(false);
    const [isPopUp, setIsPopUp] = useState(false);
    const [createDBForm, setCreateDBForm] = useState<IDataBaseConnection>(DATABASE_CONNECTION_INITIAL_OBJ);

    useEffect(() => {
        if (createDBForm.databaseType) {
            if (createDBForm.databaseType === "MSSQL") {
                setCreateDBForm((prevState: any) => ({
                    ...prevState,
                    connectionString: `Server=${prevState.server},${prevState.port};Database=${prevState.connectionName};User ID=${prevState.user};Password=${prevState.password};`,
                }));
            }
            if (createDBForm.databaseType === "MYSQL") {
                setCreateDBForm((prevState: any) => ({
                    ...prevState,
                    connectionString: `server=${prevState.server};port=${prevState.port};database=${prevState.connectionName};uid=${prevState.user};password=${prevState.password};`,
                }));
            }

            if (createDBForm.databaseType === "Postgress") {
                setCreateDBForm((prevState: any) => ({
                    ...prevState,
                    connectionString: `Host=${prevState.server};Port=${prevState.port};Username=${prevState.user};Password=${prevState.password};Database=${prevState.connectionName};`,
                }));
            }
            if (createDBForm.databaseType === "SQLite") {
                setCreateDBForm((prevState: any) => ({
                    ...prevState,
                    connectionString: `Host=${prevState.server};Port=${prevState.port};Username=${prevState.user};Password=${prevState.password};Database=${prevState.connectionName};`,
                }));
            }
        }
    }, [
        createDBForm.server,
        createDBForm.port,
        createDBForm.databaseName,
        createDBForm.user,
        createDBForm.password,
    ]);

    const handleCreateDatabase = () => {
        setIsPopUp(true);
        setTableList([]);
        isDatabaseEdit.current = false;
        // setShowViewBtn(true)
        // setShowKeysFormPopup(true);
        setCreateDBForm(DATABASE_CONNECTION_INITIAL_OBJ);
    };

    const updateDatabaseForm = (target: any) => {
        let name = target.name;
        let val = target.value;
        if (name === "port") {
			val = val.replace(/\D/g, '')
		}
        setCreateDBForm({
            ...createDBForm,
            [name]: val,
        });
        DisableSubmitButton.current = false
    };



    const handleCheckboxChange = (tableName: string) => {
        const updatedTableList = tableList.map((item) => {
            if (Object.keys(item)[0] === tableName) {
                return { [tableName]: !item[tableName] };
            }
            return item;
        });
        setTableList(updatedTableList);
        setCreateDBForm((prevState: IDataBaseConnection) => ({
            ...prevState,
            tables: updatedTableList,
        }));
    };

    const validateDatabaseForm = () => {
        let flag = true;

        if (!createDBForm.connectionName) {
            toastError("Please enter connection name");
            flag = false
        }

        if (!createDBForm.databaseName) {
            toastError("Please Enter Database Name");
            flag = false;
        }
        if (!createDBForm.databaseType) {
            toastError("Please Select Database Type");
            flag = false;
        }
        if (createDBForm.databaseType !== "SQLite") {
            if (!createDBForm.server) {
                toastError("Please Enter Server");
                flag = false;
            }
            if (!createDBForm.user) {
                toastError("Please Enter User ID");
                flag = false
            }
            if (!createDBForm.password) {
                toastError("Please Enter Password");
                flag = false
            }
            if (!createDBForm.port) {
                toastError("Please Enter Port");
                flag = false
            }
        }

        return flag;
    };

    const validateDatabaseTable = () => {
        let flag = true;

        if (!createDBForm.tables) {
            toastError("Please select at least one table");
            flag = false;
        }
        if (createDBForm.tables && !createDBForm.tables?.map(item=>Object.values(item)[0])?.some(v=>v==true)) {
            toastError("Please select at least one table");
            flag = false;
        }
        return flag;
    };

    const showKeysEditFormPopup = (row: any) => {
        // setTableList([])
        setShowViewBtn(false);
        setShowKeysFormPopup(true);
        isDatabaseEdit.current = true;

        const parts = row.connectionString
            .split(";")
            .map((part: any) => part.split("="));
        const parsedData = Object.fromEntries(parts);

        let server =
            parsedData["Server"]?.split(",")[0] ||
            parsedData["Host"] ||
            parsedData["server"];
        let port =
            parsedData["Server"]?.split(",")[1] ||
            parsedData["Port"] ||
            parsedData["port"];
        let databaseName = parsedData["Database"] || parsedData["database"];
        let user =
            parsedData["User ID"] || parsedData["Username"] || parsedData["uid"];
        let password = parsedData["Password"] || parsedData["password"];

        setCreateDBForm((prevState: IDataBaseConnection) => ({
            ...prevState,
            id: 0,
            connectionName: row.connectionName,
            databaseType: row.databaseType,
            connectionString: row.connectionString,
            server: row.server ? row.server : server,
            port: row.port ? row.port : port,
            databaseName: row.databaseName ? row.databaseName : databaseName,
            user: row.user ? row.user : user,
            password: row.password ? row.password : password,
            tables: row.tables,
            // selectedTables: typeof row.selectedTables === 'string' ? JSON.parse(row.selectedTables) : row.selectedTables || []
        }));
        setTableList(row.tables || []);
    };

    const hideKeysFormPopup = () => {
        setShowKeysFormPopup(false);
    };

    const getTables = async () => {
        setShowLoader(true);
        await AgentService.GetDatabaseTables(createDBForm)
            .then((res) => {
                const initialTableList = Array.isArray(res.response)
                    ? res.response.map((tableName) => ({ [tableName]: false }))
                    : [];
                setTableList(initialTableList);
                setShowKeysFormPopup(true);
                setIsPopUp(false);
            })
            .catch((err) => {
                console.log(err);
                setShowLoader(false);
                toastError(err?.response?.data?.response);
            })
            .finally(() => {
                setShowLoader(false);
            });
    };

    const createDatabaseForm = async () => {
        if (!validateDatabaseForm() || !validateDatabaseTable()) return;
        setShowLoader(true);
        setButtonDisable(true);
        SetUpdateAssistant((preVal) => ({
            ...preVal,
            databaseConnections: [
                ...(preVal.databaseConnections || []),
                createDBForm,
            ],
        }));
        // isDatabaseEdit.current ? toastSuccess("Database Updated Successfully") : toastSuccess("Database Modified Successfully");
        setButtonDisable(false);
        setShowKeysFormPopup(false);
        setShowLoader(false);
        toastSuccess("Database is connected successfully");
    };

    const updateDatabaseSubmitForm = async () => {
        if (!validateDatabaseForm() || !validateDatabaseTable()) return;
        setShowLoader(true);
        setButtonDisable(true);
        DisableSubmitButton.current = false

        SetUpdateAssistant((preVal) => {
            const updatedConnections = [...(preVal.databaseConnections || [])];
            const existingIndex = updatedConnections.findIndex(
                (conn) => conn.connectionName === createDBForm.connectionName
            );

            if (existingIndex !== -1) {
                // Update the existing connection's tables
                updatedConnections[existingIndex] = {
                    ...updatedConnections[existingIndex],
                    tables: tableList,
                };
            } else {
                // Add a new connection if it doesn't exist
                updatedConnections.push({
                    ...createDBForm,
                    tables: tableList,
                });
            }

            return {
                ...preVal,
                databaseConnections: updatedConnections,
            };
        });

        isDatabaseEdit.current
            ? toastSuccess("Database Updated Successfully")
            : toastSuccess("Database is connected successfully");

        setButtonDisable(false);
        setShowKeysFormPopup(false);
        setShowLoader(false);
    };

    const deleteDatabase = (item: IDataBaseConnection, index: number) => {
        setShowLoader(true);
        SetUpdateAssistant((preVal) => {
            let updatedDBCon = [...(preVal.databaseConnections || [])];
            updatedDBCon.splice(index, 1);
            return {
                ...preVal,
                databaseConnections: updatedDBCon,
            };
        });
        setShowLoader(false);
        DisableSubmitButton.current = false
        // toastSuccess("Database Deleted Successfully");
    };

    const deleteDatabaseConfirm = (item: IDataBaseConnection, index: number) => {
        confirmAlert({
            title: "Confirm Delete",
            message: "Are you sure you want to delete this database?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        deleteDatabase(item, index);
                    },
                },
                {
                    label: "No",
                    onClick: () => { },
                },
            ],
        });
    };

    /// pop up
    const createConnectionString = () => {
        setShowLoader(false);
        if (!validateDatabaseForm()) return;
        if (
            UpdateAssistant.databaseConnections?.some(
                (item) => item.connectionName === createDBForm.connectionName
            )
        ) {
            toastError("Connection name already exists");
        } else {
            setTableList([]);
            getTables();
            isDatabaseEdit.current = true
        }
    };

    const closeEosPopup = () => {
        setIsPopUp(false);
    };
    const databaseTableColumns: TableColumn<any>[] = [
        {
            name: "Name",
            selector: (row: any) => Object.keys(row)[0],
            sortable: true,
        },
        {
            name: "Action",
            cell: (row: any) => (
                <div>
                    <input
                        type="checkbox"
                        // checked={createItemForm.selectedTables[Object.keys(row)[0]]}
                        checked={row[Object.keys(row)[0]]}
                        onChange={() => handleCheckboxChange(Object.keys(row)[0])}
                    />
                </div>
            ),
        },
    ];
    const tableColumns: TableColumn<any>[] = [
        {
            name: "Name",
            selector: (row: any) => (
                <div
                    style={{
                        maxWidth: "100px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                    title={row.connectionName}
                >
                    {row.connectionName}
                </div>
            ),
            sortable: true,
            width: "100px",
        },
        {
            name: "Database Type",
            selector: (row: any) => row.databaseType,
            sortable: true,
            width: "200px",
        },

        {
            name: "Server",
            selector: (row: any) => row.server,
            sortable: true,
            cell: (row: any) => (
                <div
                    style={{
                        maxWidth: "100px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    }}
                    title={row.server}
                >
                    {row.server.length > 10
                        ? `${row.server.substring(0, 10)}...`
                        : row.server}
                </div>
            ),
        },
        {
            name: "Database",
            selector: (row: any) => row.databaseName,
            sortable: true,
            width: "200px",
        },
        {
            name: "User ID",
            selector: (row: any) => row.user,
            sortable: true,
            width: "200px",
        },
        {
            name: "Port",
            selector: (row: any) => row.port,
            sortable: true,
            width: "100px",
        },
        // {
        //     name: "Connection String",
        //     selector: (row: any) => row.connectionString,
        //     sortable: true,
        //     cell: (row: any) => (<div style={{ maxWidth: "550px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }} title={row.connectionString}>
        //         {row.connectionString.length > 250 ? `${row.connectionString.substring(0, 250)}...` : row.connectionString}
        //     </div>
        //     ),
        // },
        {
            name: " ",
            sortable: false,
            button: true,
            right: true,
            cell: (row: any, rowIndex: any) => (
                <div className="gridIcons" data-tag="allowRowEvents">
                    <>
                        <>
                            <div>
                                <button
                                    type="button"
                                    className="btn plainBtn editBtn"
                                    title="Edit"
                                    onClick={() => {
                                        showKeysEditFormPopup(row);
                                        clickedPartIndexRef.current = rowIndex;
                                    }}
                                >
                                    <LuPen />
                                </button>
                                <button
                                    type="button"
                                    className="btn plainBtn deleteBtn"
                                    title="Delete"
                                    onClick={() => deleteDatabaseConfirm(row, rowIndex)}
                                >
                                    <LuTrash />
                                </button>
                            </div>
                        </>
                    </>
                </div>
            ),
        },
    ];
    return (<>
        <div className="commonDiv">
            <div className="titleBar d-flex align-items-center">
                <h3> Database <span>({UpdateAssistant.databaseConnections?.length})</span></h3>
                <div className="ms-auto">
                    <div className="d-flex gap-2">


                        <button
                            className="btn plainBtn ms-auto"
                            type="button"
                            disabled={UpdateAssistant.databaseConnections?.length==1}
                            onClick={handleCreateDatabase}
                        >
                            <LuPlus />
                        </button>

                    </div>
                </div>
            </div>


            <div className="dataTable">
                {UpdateAssistant?.databaseConnections && (
                    <DataTable
                        noHeader={true}
                        columns={tableColumns}
                        data={UpdateAssistant.databaseConnections}
                        // pagination
                        // paginationServer
                        pagination={true}
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[10, 15, 20]}
                    />
                )}
            </div>


            {showKeysFormPopup && (
                <div className="popup-container">
                    <div className="shadeClose" onClick={() => {
                        hideKeysFormPopup();
                    }}></div>
                    <div className="popup-inner">
                        <div className="inner-design">
                            <div className="popup-header">
                                {/* <h3>{isDatabaseEdit.current ? "Database Details" : "Create New Database"}</h3> */}
                                <h3>{createDBForm.connectionName}</h3>
                                <button
                                    type="button"
                                    onClick={() => {
                                        hideKeysFormPopup();
                                    }}
                                    className="close"
                                ><FaTimes /></button>
                            </div>

                            <div className="popup-content">
                                <div className="row">
                                    {createDBForm.databaseType !== "SQLite" && (
                                        <div className="col-12 col-md-5 formHolder nameHolder mb-4">
                                            <label className="form-label">
                                                User ID <span className="danger">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                name="user"
                                                className="form-control"
                                                onChange={(event) => updateDatabaseForm(event.target)}
                                                disabled={isDatabaseEdit.current}
                                                value={createDBForm.user}
                                            />
                                        </div>
                                    )}
                                    <div className="col-12 col-md-5 formHolder nameHolder mb-4">
                                        <label className="form-label">
                                            Database Type <span className="danger">*</span>
                                        </label>
                                        <select name="databaseType" className="form-select" disabled={isDatabaseEdit.current}
                                            value={createDBForm.databaseType}
                                            onChange={(event) => updateDatabaseForm(event.target)}>
                                            <option value="MYSQL">MySQL</option>
                                            <option value="POSTGRESS">PostgreSQL</option>
                                            <option value="MSSQL">Microsoft SQL Server</option>
                                            <option value="Oracle">Oracle</option>
                                            <option value="MariaDB">MariaDB</option>
                                            <option value="MongoDB">MongoDB</option>
                                            <option value="AzureCosmosDB">Azure Cosmos DB</option>
                                            <option value="AzureSQLDB">Azure SQL DB</option>
                                            <option value="AzureBlobStorage">Azure Blob Storage</option>
                                            <option value="SQLite">SQLite</option>
                                        </select>
                                    </div>
                                    {createDBForm.databaseType !== "SQLite" && (
                                        <div className="col-12 col-md-2 formHolder nameHolder mb-4">
                                            <label className="form-label">
                                                Port <span className="danger">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                name="port"
                                                className="form-control"
                                                onChange={(event) => updateDatabaseForm(event.target)}
                                                disabled={isDatabaseEdit.current}
                                                value={createDBForm.port}
                                            />
                                        </div>
                                    )}
                                    <div className="col-12 col-md-5 formHolder nameHolder mb-4">
                                        <label className="form-label">
                                            Database <span className="danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="databaseName"
                                            className="form-control"
                                            onChange={(event) => updateDatabaseForm(event.target)}
                                            disabled={isDatabaseEdit.current}
                                            value={createDBForm.databaseName}
                                        />
                                    </div>
                                    {createDBForm.databaseType !== "SQLite" && (
                                        <div className="col-12 col-md-7 formHolder nameHolder mb-4">
                                            <label className="form-label">
                                                Server <span className="danger">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                name="server"
                                                className="form-control"
                                                onChange={(event) => updateDatabaseForm(event.target)}
                                                disabled={isDatabaseEdit.current}
                                                value={createDBForm.server}
                                            />
                                        </div>
                                    )}


                                </div>

                                {tableList && (
                                    <div className="permissionTable">
                                        <h3>Tables</h3>
                                        <DataTable
                                            columns={databaseTableColumns}
                                            data={tableList ? tableList : []}
                                        />
                                    </div>
                                )}


                                <div className="d-flex gap-2 mt-2">
                                    {isDatabaseEdit.current ? (

                                        <button className="btn btn-primary ms-auto" onClick={() => updateDatabaseSubmitForm()} disabled={buttonDisable}>Done</button>
                                    ) : (
                                        <button className="btn btn-primary ms-auto" onClick={() => createDatabaseForm()}>Done</button>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>)}
        </div>
        {isPopUp && (
            <div className="popup-container create-template-popup">
                <div
                    className="shadeClose"
                    onClick={() => {
                        closeEosPopup();
                    }}
                ></div>
                <div className="popup-inner">
                    <div className="inner-design">
                        <div className="popup-header">
                            <h3>New Connection</h3>
                            <button
                                type="button"
                                onClick={() => {
                                    closeEosPopup();
                                }}
                                className="close"
                            >
                                <FaTimes />
                            </button>
                        </div>

                        <div className="popup-content">
                            <div className="row">
                                <div className="col-12 col-md-6 formHolder nameHolder mb-3">
                                    <label className="form-label">
                                        Connection Name <span className="danger">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Enter the connection name"
                                        name="connectionName"
                                        className="form-control"
                                        onChange={(event) => updateDatabaseForm(event.target)}
                                        disabled={isDatabaseEdit.current}
                                        value={createDBForm.connectionName}
                                    />
                                </div>
                                <div className="col-12 col-md-6 formHolder nameHolder mb-3">
                                    <label className="form-label">
                                        Database Type <span className="danger">*</span>
                                    </label>
                                    <select
                                        name="databaseType"
                                        className="form-select"
                                        disabled={isDatabaseEdit.current}
                                        value={createDBForm.databaseType}
                                        onChange={(event) => updateDatabaseForm(event.target)}
                                    >
                                        <option value="" selected>Select Database Type</option>
                                            <option value="MYSQL">MySQL</option>
                                            <option value="POSTGRESS">PostgreSQL</option>
                                            <option value="MSSQL">Microsoft SQL Server</option>
                                            <option value="Oracle">Oracle</option>
                                            <option value="MariaDB">MariaDB</option>
                                            <option value="MongoDB">MongoDB</option>
                                            <option value="AzureCosmosDB">Azure Cosmos DB</option>
                                            <option value="AzureSQLDB">Azure SQL DB</option>
                                            <option value="AzureBlobStorage">Azure Blob Storage</option>
                                            <option value="SQLite">SQLite</option>
                                    </select>
                                </div>

                                {createDBForm.databaseType !== "SQLite" && (
                                    <div className="col-12 col-md-6 formHolder nameHolder mb-3">
                                        <label className="form-label">
                                            Server <span className="danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            placeholder="Enter the Server"
                                            name="server"
                                            className="form-control"
                                            onChange={(event) => updateDatabaseForm(event.target)}
                                            disabled={isDatabaseEdit.current}
                                            value={createDBForm.server}
                                        />
                                    </div>
                                )}
                                <div className="col-12 col-md-6 formHolder displayHolder mb-3">
                                    <label className="form-label">
                                        Database Name <span className="danger">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Enter the Database Name"
                                        name="databaseName"
                                        className="form-control"
                                        onChange={(event) => updateDatabaseForm(event.target)}
                                        disabled={isDatabaseEdit.current}
                                        value={createDBForm.databaseName}
                                    />
                                </div>

                                {createDBForm.databaseType !== "SQLite" && (
                                    <div className="col-12 col-md-6 formHolder nameHolder mb-3">
                                        <label className="form-label">
                                            User ID <span className="danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            placeholder="Enter the userID"
                                            name="user"
                                            className="form-control"
                                            onChange={(event) => updateDatabaseForm(event.target)}
                                            disabled={isDatabaseEdit.current}
                                            value={createDBForm.user}
                                            autoComplete="nope"

                                        />
                                    </div>
                                )}
                                {createDBForm.databaseType !== "SQLite" && (
                                    <div className="col-12 col-md-6 formHolder displayHolder mb-3">
                                        <label className="form-label">
                                            Password <span className="danger">*</span>
                                        </label>
                                        <input
                                            type="Password"
                                            placeholder="Enter the Password"
                                            name="password"
                                            className="form-control"
                                            onChange={(event) => updateDatabaseForm(event.target)}
                                            disabled={isDatabaseEdit.current}
                                            value={createDBForm.password}
                                            autoComplete="nope"
                                        />
                                    </div>
                                )}
                                <div className="col-12">
                                    <div className="row align-items-end">
                                        {createDBForm.databaseType !== "SQLite" && (
                                            <div className="col-12 col-md-3 formHolder nameHolder ">
                                                <label className="form-label">
                                                    Port <span className="danger">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Enter the Port"
                                                    name="port"
                                                    className="form-control"
                                                    onChange={(event) =>
                                                        updateDatabaseForm(event.target)
                                                    }
                                                    disabled={isDatabaseEdit.current}
                                                    value={createDBForm.port}
                                                />
                                            </div>
                                        )}
                                        <div className="ms-auto col-12 col-md-6 d-flex">
                                            <div className="ms-auto ">
                                                <div className="btn-holder gap-3 d-flex">
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary "
                                                        onClick={() => closeEosPopup()}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        className="btn btn-primary"
                                                        type="button"
                                                        onClick={() => createConnectionString()}
                                                    >
                                                        Connect
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )}
    </>)
}
export default Database